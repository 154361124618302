body
  margin: 0
  font-family: $regular-text
  overflow: hidden
  color: $dark

ul
  list-style-type: none
  padding: 0

li
  margin: 0

input
  font-size: 12px

h1
  margin: 0

a
  text-decoration: none
  color: inherit

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
.base-drop-down
  border: 1px solid silver
  border-radius: $layout-border-radius
  height: 30px
  padding: 5px 7px
  font-family: $title-text
  font-size: 12px
  &[disabled]
    cursor: not-allowed

input:focus, textarea:focus, select:focus
  outline: 2px solid $primary

button, .btn
  display: inline-flex
  align-items: center
  justify-content: center
  font-family: $regular-text
  font-size: 12px
  border-radius: 30px
  min-height: 30px
  padding: 5px 15px
  box-sizing: content-box
  border: 1px solid $primary
  background-color: $primary
  color: $white
  cursor: pointer
  transition: $transition-default
  &:hover
    background-color: transparent
    color: $primary
  &.danger
    background-color: $danger
    border: 1px solid $danger
    &:hover
      background-color: transparent
      color: $danger
      outline: 1px solid $danger
  &.negative
    background-color: $dark
    border: 1px solid $dark
    &:hover
      background-color: transparent
      color: $dark
      outline: 1px solid $dark

.back-button
  cursor: pointer
  height: 40px
  width: 40px
  border-radius: 50%
  background-color: $secondary
  color: $white
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.5)
  z-index: 10
  display: flex
  align-items: center
  justify-content: center
  font-size: 20px
  transition: $transition-default
  &:hover
    background-color: lighten($secondary, 20)

.row
  display: flex

.big-button
  background-color: $secondary
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  padding: 30px 10px
  border-radius: 3px
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3)
  cursor: pointer
  margin: 20px
  width: 150px
  height: 100px
  transition: all 0.3s cubic-bezier(.77,1.76,.4,.77)
  i
    font-size: 60px
    color: $white
    margin-bottom: 10px
  label
    margin: 0
    color: $white !important
    text-align: center
    font-family: $menu-text
  &:hover
    transform: scale(1.05)
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.3)

i.material-icons.ok
  color: $ok

i.material-icons.warning
  color: $warning

i.material-icons.danger
  color: $danger

.card
  background-color: $secondary
  color: white
  padding: 15px
  max-height: calc(100vh - 80px)
  header
    padding: 15px
  .body
    padding: 15px
  footer
    display: flex
    justify-content: right
    *
      margin-left: 10px

.table
  width: 1000px
  header
    background-color: $third
    border: 1px solid $white
    display: flex
    padding: 0px
    div
      flex: 2
      height: 20px
      min-width: 100px
      &.action
        flex: 1
  .table-body
    max-height: 100%
    overflow-y: scroll
    .table-row
      display: flex
      &:nth-child(even)
        div
          background-color: $alternate-row
      &:hover
        div
          background-color: $dark
          color: $white
      div
        flex: 3
        padding: 10px 10px
        background-color: $white
        color: $third
        min-width: 100px
        font-size: 12px
        cursor: default
        transition: $transition-default
        &.action
          padding-top: 0
          padding-bottom: 0
          height: 36px
          display: flex
          align-items: center
          flex: 1
          button, .btn
            margin: 0
            height: 10px
            border-radius: 20px
            padding: 0px 20px
