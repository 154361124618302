#container
  z-index: 1
  position: fixed
  top: 0
  left: $menu-width
  width: $container-width
  height: 100vh
  background-color: $secondary
  .content
    background-color: $white
    height: 100%
    width: 100%
    border-radius: 40px
    overflow: hidden
    padding: 0
    transition: border-radius 0.3s ease-in-out 1s
    &.hidden
      border-radius: 0px