.content
  .l-settings.header
    position: relative
    margin: 0
    padding: 0
    height: 100%
    width: 100%
    background-image: url('../../../../public/bg-06.jpg')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-attachment: fixed

.l-settings
  .options
    display: flex
    align-items: flex-start
    width: 100%

.l-settings
  .card
    background-color: $secondary
    color: $white
    padding: 30px
    border-radius: 3px
    box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.3)
    margin: 10px
    h1
      text-align: left
      margin: 0
    .field
      display: flex
      font-size: 14px
      label
        margin-right: 10px
        font-weight: bold
        width: 70px
        text-align: right
    footer
      display: flex
      flex-direction: row-reverse
      padding-bottom: 0
      button
        width: 100px
        padding: 0px 5px
        margin-left: 5px

.card
  max-height: 100vh
  overflow-y: scroll
  header
    padding: 15px
    margin: 0
    h1
      margin: 0
      padding: 0
  .body
    padding: 15px
    display: flex
    flex-direction: column
    width: 90%
    margin: 0
    .field
      margin-bottom: 10px
      width: 100%
      input[type="password"],
      input[type="email"],
      input[type="text"]
        width: 100%
  footer
    padding: 15px

.card#cardEditProfile
  padding: 15px
  min-width: 400px
  .body
    height: auto

.card#cardChangePassword
  padding: 15px
  min-width: 400px
  footer
    button
      width: 150px

#changePasswordBack
  position: absolute
  top: 50px
  left: calc(50% - 500px)

@media only screen and (max-width: 2000px)
  #changePasswordBack
    left: calc(50% - 300px)
