.c-alert-message
  background-color: $danger
  color: $white
  border-radius: 3px
  box-shadow: 0px 0px 3px 1px $dark
  opacity: 0
  transform: translateY(-20px)
  transition: $transition-default
  &.alert
    background-color: $danger
  &.info
    background-color: $ok
  &.show
    padding: 10px
    opacity: 1
    transform: translateY(0px)
