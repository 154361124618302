@import 'Form'

.elocks-screen
  width: 100%
  height: 100%
  background-color: transparent
  background-image: url('../../../../public/bg-09.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed
  display: flex
  position: relative
  .c-vehicles-list
    height: 95%
    position: relative
    top: 20px
    left: 40px
    border-radius: 8px
  .elocks-screen-form
    height: max-content
    max-width: 300px
    margin: 0 auto
    position: relative
    top: 20px
    flex: 1
    display: flex
    justify-content: center

@media only screen and (max-width: 850px)
  .elocks-screen
    flex-direction: column-reverse
    justify-content: center
    .c-vehicles-list
      height: 50%
      top: 0
      left: 0
      margin: 0 auto 12px
    .elocks-screen-form
      top: 0
      margin-bottom: 20px
      flex: 0
