.search-bar
  z-index: 1
  border-radius: 40px 40px 0 0
  padding: 10px 0px 10px 0px
  position: fixed
  top: 0
  right: 0
  width: calc(100% - 80px)
  height: 66px
  background-color: $white
  color: $dark
  display: flex
  justify-content: space-around
  align-items: center
  box-shadow: -1px 0px 6px 6px rgba(0,0,0,0.5)
  .field
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    label.title
      font-size: 12px
      min-height: 20px
    input
      height: 20px
    input[type="date"]
      height: 0px
      width: 0px
      margin: 0px
      padding: 0px
      border: none
    .input-field, label.control
      width: 200px
      height: 28px
      color: $dark
      background-color: $white
      border: 1px solid silver
      border-radius: 3px
      display: flex
      overflow: hidden
      justify-content: space-between
      padding: 0px 7px
      align-items: center
      font-size: 12px
      input
        border: none
        width: 100px
        flex: 1
        border-right: 1px solid lightgray
      i
        color: $dark
        transition: all 0.3s ease-in-out
        transform: rotateZ(0deg)
      &.selected
        i
          transform: rotateZ(180deg)
    .searchbar-dropdown
      overflow: hidden
      background-color: $white
      color: $dark
      padding: 5px
      position: absolute
      height: 0px
      padding-top: 0px
      padding-bottom: 0px
      transform: translateY(-50%)
      transition: all 0.3s ease-in-out
      box-shadow: -1px -1px 5px 2px rgba(0,0,0,0)
      li
        transition: all 0.3s ease-in-out
        cursor: pointer
        padding: 5px 7px
        &:hover
          background-color: $dark
          color: $white
      &.selected
        height: auto
        padding-top: 5px
        padding-bottom: 5px
        box-shadow: -1px -1px 5px 2px rgba(0,0,0,0.3)
    label.control
      cursor: pointer
      width: 120px
      justify-content: space-around
    #btnBuscar
      height: 20px
      min-height: 20px
      width: 100px
      border: none
      margin: 0
      font-size: 12px
      font-family: $regular-text
      color: $white
      background-color: $primary
      display: flex
      align-items: center
      justify-content: space-around
      cursor: pointer
      border-radius: 35px
      transition: all 0.3s ease-in-out
      border: 2px solid $primary
      &:hover
        background-color: transparent
        color: $primary
    #btnDownload
      width: 100px
      cursor: pointer
      label
        border: none
        background-color: transparent
        color: $dark
        cursor: pointer
        font-family: $regular-text
        font-size: 14px
      i
        transition: all 0.3s ease-in-out
        transform: rotatez(0deg)
      &.selected
        i
          transform: rotatez(180deg)
    #btnDownloadList
      width: 200px
      top: calc(100% + 10px)
      &.selected
        height: auto

@media only screen and (max-width: 2000px)
  #container
    width: calc(100vw - 60px)
    left: 60px
  .search-bar
    width: calc(100vw - 60px)
