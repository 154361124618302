.l-modal-question
  background-color: $white
  padding: 15px
  max-width: 600px
  cursor: default
  header
    padding: 15px
    h2
      margin: 0
  div.body
    padding: 15px
    border-top: 1px solid silver
    border-bottom: 1px solid silver
    width: auto
  footer
    padding: 15px
    display: flex
    justify-content: right
    *
      margin-left: 10px
