.search-bar.l-connections
  .field
    &.dropdown
      width: 200px
    .base-drop-down
      padding: 0
      .input
        width: 200px
      ul
        width: 200px
    input[type="number"]
      width: 50px
      text-align: center
    &.small
      min-width: 150px
      display: flex
      flex-direction: row
      div
        display: flex
        flex-direction: column
        align-items: center
        margin-left: 15px
        div
          display: flex
          flex-direction: row
          span
            font-size: 12px
            margin-left: 10px
  .field
    position: relative
    .selector-box
      transition: $transition-default
      top: 0
      left: 0
      opacity: 0
      position: absolute
      width: 400px
      height: 0
      background-color: $white
      box-shadow: $layout-shadow
      overflow: hidden
      &.open
        top: 80px
        opacity: 1
        height: 300px
      ul.c-vehicles-check-list
        height: 290px
        overflow: hidden
        overflow-y: scroll
        top: 130px
