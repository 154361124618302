.content
  .l-home.header
    margin: 0
    padding: 0
    height: 100%
    width: 100%
    background-image: url('../../../../public/bg-04.webp')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-attachment: fixed
    position: relative
    img
      position: absolute
      top: 10%
