.c-vehicles-list
  .c-vehicles-list-body
    ul.vehicle-compact-list
      li
        .selected
          background-color: #f4f8dd
          &:hover
            background-color: #f4f8dd

.c-vehicles-list
  width: 376px
  height: calc(100vh - 100px)
  border: 1px solid #748088
  display: flex
  flex-direction: column
  transition: $transition-default
  background-color: $white
  header
    .filter
      height: 47px
      padding: 5px 10px
      display: flex
      align-items: center
      justify-content: space-between
      .filter-bar-group
        width: 200px
        height: 28px
        border: 1px solid #ddd
        border-radius: 5px
        display: flex
        flex-direction: row
        position: relative
        .svg-icon-module__svg___Gd7iB.svg-icon-module__default-color___zK2Yx.t3sel-vehicles-icon
          position: absolute
          left: 5px
          top: 5px
        label
          cursor: pointer
          margin-top: 7px
          margin-left: 27px
          width: 100%
          font-size: 12px
        .filter-bar-group-box
          position: absolute
          background-color: $white
          width: 190px
          max-height: 250px
          overflow: scroll
          box-shadow: $layout-shadow
          top: 0px
          left: 0px
          opacity: 0
          height: 0px
          z-index: 9
          display: flex
          flex-direction: column
          padding: 5px
          transition: $transition-default
          label
            width: auto
            margin: 0
            padding: 5px
            cursor: pointer
            transition: $transition-default
            font-size: 12px
            &:hover
              background-color: #e3e6e7
          &.open
            opacity: 1
            top: 30px
            height: auto
      .filter-bar-vehicle
        width: 100px
        height: 28px
        border-radius: 5px
        input[type="text"]
          height: 100%
          width: 100px
          padding-top: 0
          padding-bottom: 0
          box-sizing: border-box
      .filter-bar-options
        width: 28px
        height: 28px
        background-color: #D0D4D7
        border-radius: 50%
        cursor: pointer
        &:hover
          background-color: darken(#D0D4D7, 25)
          span
            fill: white
        span
          height: 28px
          width: 28px
          display: flex
          align-items: center
          justify-content: center
  .c-vehicles-list-body
    overflow: hidden
    overflow-y: scroll
    flex: 1
    width: 100%
    ul.vehicle-compact-list
      margin-top: 0
      display: flex
      flex-direction: column
      li
        div
          height: 70px
          display: flex
          flex-direction: row
          cursor: pointer
          transition: all 0.1s ease-in-out
          &.inactive
            opacity: 0.66
          &:hover
            background-color: #e3e6e7
          figure
            width: 70px
            height: 70px
            margin: 0
            padding: 0
            display: flex
            align-items: center
            justify-content: center
            position: relative
            img
              width: 28px
              height: 28px
              padding: 10px
              background-color: #e3e6e7
              border-radius: 50%
              border: 2px solid white
            .status
              position: absolute
              top: calc(50% + 10px)
              left: calc(50% + 10px)
              width: 10px
              height: 10px
              border: 2px solid white
              border-radius: 50%
              &.ok
                background-color: #7baa21
              &.inactive
                background-color: #a2aaaf
          figcaption
            flex: 1
            display: flex
            justify-content: center
            flex-direction: column
            padding-right: 15px
            h2
              margin: 0
              font-weight: 400px
              font-size: 13px
              height: 20px
              overflow: hidden
              text-overflow: ellipsis
            .info
              height: 30px
              font-size: 12px
              font-style: normal
              text-overflow: ellipsis
              overflow: hidden
              color: #b7bfc4
              svg
                margin-top: -10px
                margin-right: 5px
                transform: translateY(3px)
    .empty-search
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      fill: #a2aaaf
      color: #a2aaaf
