.c-switch
  display: flex
  align-items: center
  input[type="checkbox"]+label
    display: block
    height: 30px
    width: 40px
    position: relative
    cursor: pointer
    &+label
      padding-left: 20px
      font-size: 14px
      display: block
      cursor: pointer
    &::before
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      border-radius: 15px
      content: ""
      height: 15px
      width: 30px
      background-color: silver
      box-shadow: 2px 2px 3px 3px rgba(0,0,0,0.15)
      transition: all 0.2s ease-in-out
    &::after
      position: absolute
      top: 50%
      left: 0px
      transform: translate(0px, -50%)
      content: ""
      height: 25px
      width: 25px
      border-radius: 50%
      background-color: #999
      transition: all 0.2s ease-in-out
      box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1)
    &:hover
      &::before
        background-color: #aaa
      &::after
        background-color: $dark
        box-shadow: 0px 0px 0px 10px rgba(0,0,0,0.1)

  input[type="checkbox"]:checked+label
    &::before
      background-color: dodgerblue
    &::after
      background-color: dodgerblue
      transform: translate(60%, -50%)

.c-switch
  input[type="checkbox"]
    display: none
