.search-bar.l-position-log
  box-sizing: border-box
  padding: 0px 100px 0px 100px

#full-data.l-position-log
  background-color: transparent
  background-image: url('../../../../public/bg-09.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed
  h1
    color: $white
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5)
    margin-bottom: 10px
  .table
    background-color: $white
    width: auto
    .head,.row
      .col.coord
        min-width: 170px
        span
          display: flex
          align-items: center
          justify-content: space-between
      .col.date
        min-width: 130px
