.bg
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  background-image: url('../../../public/bg-01.jpg')
  filter: brightness(80%)
  background-size: cover
  background-position: center
  z-index: -1

#frmLogin
  background-color: rgba(255,255,255,0.9)
  box-shadow: $layout-shadow
  position: fixed
  top: 0
  left: 0
  width: 33vw
  max-width: 400px
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  .separator
    height: 1px
    width: 300px
    background-color: silver
  img
    width: 250px
    margin-bottom: 40px
    margin-top: 65px
  .col
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 50px
    &:nth-child(3)
      height: 200px
      padding-top: 50px
      padding-bottom: 50px
      justify-content: space-between
      .base-drop-down,input,button
        width: 200px

@media only screen and (max-width: 1200px)
  #frmLogin
    img
      left: calc(50% - 100px)
      width: 200px
