.c-date
  min-width: 130px
  &.control
    position: relative
    &+input
      position: relative
      left: -50%
      top: -30px
      opacity: 0

.react-datepicker-wrapper
  height: 0
  width: 0
  position: relative
  left: -50%
  top: 0px
  opacity: 0

.react-datepicker__triangle
  left: 20px !important

.react-datepicker__time-list
  transform: translateY(0px) !important
  border: 1px solid silver !important

.react-datepicker__time-list-item
  display: flex
  align-items: center
  &:hover
    color: $dark !important

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected
  background-color: $primary !important

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box,
li.react-datepicker__time-list-item--selected
  background-color: $primary !important
  &:hover
    background-color: $primary !important
