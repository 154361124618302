// https://coolors.co/79bc22-011827-01121d-ffffff-6f6f6f

$primary: #79bc22
$secondary: #011827
$third: #01121d

$white: #ffffff
$dark: #6f6f6f

$alternate-row: #f4f4f4
$danger: #782816
$ok: #319c1c
$warning: gold

$menu-width: 80px
$container-width: calc(100vw - 80px)

$regular-text: 'Montserrat', sans-serif
$title-text: 'Nunito', sans-serif
$menu-text: 'Roboto Condensed', sans-serif
$header-text: 'Roboto Serif', serif
$header-text: 'Roboto', sans-serif

$layout-shadow: 1px 1px 15px 5px rgba(0,0,0,0.3)
$layout-border-radius: 3px

$transition-default: all 0.3s ease-in-out
