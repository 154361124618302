header#main-menu
  font-family: $menu-text
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: $menu-width
  background-color: $secondary
  display: flex
  flex-direction: column
  align-items: center
  z-index: 2
  color: $white
  .logo
    margin-top: 20px
    cursor: pointer
    overflow: hidden
    height: 50px
    width: 50px
    display: flex
    align-items: center
    justify-content: flex-end
    border-radius: 10px
    background-color: $white
    a
      display: block
      height: 100%
      width: 100%
      img
        height: 100%
  nav
    flex: 1
    ul
      display: flex
      flex-direction: column
      height: calc(100% - 50px)
      li
        &.space
          flex: 1
        a.menu-item
          padding-top: 5px
          padding-bottom: 5px
          display: flex
          flex-direction: column
          align-items: center
          cursor: pointer
          text-align: center
          i
            height: 40px
            width: 40px
            line-height: 40px
            font-size: 35px
            color: $white
          label
            margin-top: 5px
            font-size: 14px
            cursor: pointer
            display: block
            margin-left: 7px
            margin-right: 8px
            color: $white

#main-menu
  nav
    ul
      li
        a.menu-item
          position: relative
          transition: all 0.3s ease-in-out
          &::before
            content: ""
            z-index: -1
            width: 100%
            height: 0px
            background-color: $white
            position: absolute
            top: 50%
            transform: translateY(-50%)
            transition: all 0.2s ease-in
          &:hover, &.selected
            i,label
              color: $secondary
            &::before
              height: 100%
              transition: all 0.3s cubic-bezier(.77,1.76,.4,.77)
          &.selected
            cursor: default

@media only screen and (max-width: 2000px)
  header#main-menu
    width: 60px
    .logo
      width: 40px
      height: 40px
    nav
      ul
        li
          a.menu-item
            width: 60px
            height: 57px
            i
              font-size: 25px
            label
              font-size: 12px
