.content
    background-color: $white
    height: 100%
    width: 100%
    border-radius: 40px
    overflow: hidden
    padding: 0
    .header
      margin: 0
      padding: 0
      height: 100%
      width: 100%
      background-image: url('../../../../public/bg-02.jpg')
      background-size: cover
      background-position: center
      background-repeat: no-repeat
      background-attachment: fixed

.header
  padding: 20px !important
  display: flex
  flex-direction: column
  align-items: center
  color: $dark
  h1
    font-family: $header-text
    font-size: 30px
    display: block
    width: 100%
    text-align: center
    margin: 20px
    margin-top: 80px
    color: $white
    text-shadow: 1px 1px 3px rgba(0,0,0,0.5)
  .body
    display: flex
    width: calc(100% - $menu-width)
    height: calc(100% - 180px)
    justify-content: space-around
    .col-main
      flex: 5
      display: flex
      flex-direction: column
      align-items: center
      margin-right: 20px
      .first
        height: 200px
        width: 100%
      .more
        width: 100%
        display: flex
        margin-top: 40px
        .second
          margin-right: 20px
        .second,.third
          height: 180px
    .col-second
      flex: 4
      display: flex
      flex-direction: column
      align-items: center
      height: 100%
      .item
        flex: 1


.item,.first,.second,.third
  font-size: 50px
  width: 400px
  height: 80px
  border-radius: 5px
  background-color: rgba(255,255,255,0.9)
  color: $secondary
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.3)
.first
  font-size: 100px
.second,.third
  font-size: 70px
  flex: 1

.first,.second,.third
  font-family: $title-text
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 20px
  hgroup
    display: flex
    align-items: flex-end
    justify-content: center
    h1
      color: $dark
      font-family: $menu-text
      font-size: 80px
      margin: 0
      width: auto
      padding-bottom: 0px
    h3
      font-family: $title-text
      font-size: 20px
      margin: 0
      padding-bottom: 10px
  h2
    font-size: 40px
    text-align: center
    margin: 0

.item
  margin-bottom: 10px
  display: flex
  align-items: flex-end
  padding-bottom: 10px
  padding-right: 10px

  h2
    font-size: 20px
    margin: 0
    margin-bottom: 5px
    margin-right: 10px
    margin-left: 10px
    flex: 1
  h1
    color: $dark
    text-shadow: 1px 0px 3px rgba(0,0,0,0.3)
    font-size: 40px
    font-family: $menu-text
    margin: 0
    padding: 0
    width: auto
  h3
    font-size: 18px
    margin: 0
    margin-bottom: 5px
    overflow: hidden
    width: 40px
  &.danger
    h1
      color: $danger

.first,.second,.third,.item
  transition: all 0.3s ease-in-out
  opacity: 1
  transform: translatey(0px)
  &.in
    transition: none
    opacity: 0
    transform: translatey(20px)

@media only screen and (max-width: 2000px)
  .header
    padding: 20px !important
    h1
      font-size: 20px
      margin: 0px
      margin-bottom: 5px
      margin-top: 80px
    .body
      height: calc(100% - 150px)
      .col-main
        margin-right: 0px
        .first
          height: 150px
          width: 90%
        .more
          width: 90%
          margin-top: 20px
          .second
            margin-right: 20px
          .second,.third
            height: 140px
      .col-second
        padding-right: 80px
        .item
          width: 400px
          h2
            font-size: 16px
          h1
            font-size: 30px
            margin: 0
          h3
            font-size: 16px
  .first,.second,.third
    padding-top: 15px
    hgroup
      h1
        font-size: 60px
      h3
        font-size: 16px
        padding-bottom: 8px
    h2
      font-size: 30px

@media only screen and (max-width: 1200px)
  .header
    h1
      margin-bottom: 20px
      margin-top: 80px
    .body
      flex-direction: column
      .col-main
        margin-bottom: 10px
        .first
          width: 100%
        .more
          width: 100%
      .col-second
        padding-right: 0px
        .item
          width: 100%
