.elocks-screen-form .form
  min-width: 300px
  padding: 15px
  border-radius: 6px
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.5)
  background-color: $secondary
  .selected-vehicle
    margin-bottom: 20px
    text-align: center
    color: $white
    font-size: 18px
  .fields
    .field
      margin-bottom: 10px
      input
        box-sizing: border-box
        display: inline-block
        width: 100%
        padding: 20px 0px 20px 8px
        height: 20px
        border: none
        border-radius: 4px
        background-color: $white
        color: $dark
  .actions
    padding: 10px 0
    display: flex
    justify-content: space-between
    flex-direction: row-reverse
    .delete-btn
      background-color: $danger
      border: 1px solid $danger
    .save-btn
      position: relative
      right: 0
    button
      &:hover
        color: $white
        background-color: transparent
