ul.c-vehicles-check-list
  min-height: 290px
  position: relative !important
  margin: 7px 0px
  top: 0px !important
  li.c-vehicles-check-list-item
    display: flex
    flex-direction: row
    align-items: center
    padding: 5px 7px
    margin: 0
    cursor: pointer
    figure
      margin: 0
      margin-right: 30px
      padding: 0
    div
      display: flex
      flex-direction: row
      align-items: center
      img
        height: 26px
        width: 26px
        padding: 7px
        background-color: lighten($dark, 50)
        border-radius: 50%
        margin-right: 10px
      div
        display: flex
        flex-direction: column
        align-items: flex-start
        h3
          font-size: 12px
          margin-top: 0
          margin-bottom: 5px
        div
          span
            font-size: 12px

ul.c-vehicles-check-list
  li.c-vehicles-check-list-item
    border-bottom: 1px solid $alternate-row
    figure
      position: relative
      &::before
        position: absolute
        content: ''
        top: -7px
        left: 0
        border: 1px solid $dark
        border-radius: 3px
        height: 15px
        width: 15px
        background-color: $white
      &::after
        position: absolute
        content: ''
        top: 0
        left: 0
        border: 2px solid $ok
        border-top: none
        border-left: none
        height: 15px
        width: 7px
        opacity: 0
        transform: translateX(5px) rotateZ(45deg)
        transition: $transition-default
    &.selected
      figure
        &::after
          top: -9px
          opacity: 1

ul.c-vehicles-check-list
  li.c-vehicles-check-list-item
    transition: $transition-default
    &:hover
      background-color: $dark
      color: $white
