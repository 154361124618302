@import 'SearchBar'

#full-data.l-connections
  background-color: transparent
  background-image: url('../../../../public/bg-03.jpg')
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  background-attachment: fixed
  h1
    color: $white
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5)
    margin-bottom: 10px
  .table
    background-color: $white
    width: auto
    .head,.row
      .col
        min-width: 100px
        i
          cursor: help
        a
          cursor: pointer
          i
            cursor: pointer
          span
            text-decoration: underline
            color: royalblue
        &.img
          display: flex
          align-items: center
          justify-content: center
          flex: 0
          min-width: 60px !important
          img
            width: 26px
            height: 26px
            background-color: lighten($dark, 53)
            border: 1px solid $white
            border-radius: 50%
            overflow: hidden
            padding: 7px
        &.icon
          min-width: 70px
          flex: 0
          text-align: center
        &.date
          width: 130px
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between
    .row:hover
      .col
        a
          span
            color: $white !important
