.loading-shadow
  background-color: rgba(0,0,0,0.8)
  position: fixed
  top: 50%
  left: calc(50% + $menu-width)
  height: 0px
  width: 100vw
  transition: all 0.3s cubic-bezier(.23,.71,.76,.3)
  transform: translate(-50%, -50%)
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  opacity: 0
  z-index: 100
  &.wait
    cursor: wait
  &.active
    opacity: 1
    height: 100vh
    width: 100vw
  .loading-box
    display: flex
    flex-direction: column
    width: 300px
    align-items: center
    label
      color: $white
      margin-bottom: 10px
      width: 100%
      text-align: center
    .loading-items
      position: relative
      width: 155px
      display: flex
      height: 30px
      align-items: flex-end
      .loading-item
        border-radius: 10px
        background-color: white
        height: 10px
        width: 10px
        animation: spin 1.5s infinite

.loading-shadow
  .loading-box
    .loading-items
      @for $i from 1 through 15
        .loading-item:nth-child(#{$i})
          animation-delay: 0.1s * $i

@keyframes spin
  0%
    height: 10px
  50%
    height: 30px
  100%
    height: 10px

@media only screen and (max-width: 2000px)
  .loading-shadow
    left: calc(50% + 60px)
