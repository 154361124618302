.c-vehicle-searcher-box
  position: relative
  min-width: 150px
  .c-vehicles-list
    position: absolute
    margin-top: 10px
  .show
    opacity: 1
  .hidden
    display: none

.c-vehicle-searcher-text
  height: 30px
  min-width: 100px
  max-width: 300px
  border-radius: 3px
  border: 1px solid #ddd
  padding: 5px
  box-sizing: border-box
  font-size: 12px
  cursor: pointer
