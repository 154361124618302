ul.c-check-list
  background-color: lighten($secondary, 6)
  min-height: 40px
  max-height: calc(100vh - 290px)
  overflow: hidden
  overflow-y: scroll
  li.c-check-list-item
    cursor: pointer
    height: 40px
    width: 100%
    background-color: lighten($secondary, 6)
    display: flex
    flex-direction: row
    align-items: center
    padding: 7px 10px
    transition: all 0.3s ease-in-out
    &:nth-child(odd)
      background-color: lighten($secondary, 10)
    div
      display: flex
      flex: 1
      flex-direction: column
      div
        display: flex
        flex-direction: row
        label
          cursor: pointer
          user-select: none
          font-size: 12px
          &:nth-child(1)
            width: 70px
            text-align: right
            font-weight: bold
            margin-right: 10px

ul.c-check-list
  li.c-check-list-item
    &:hover
      background-color: lighten($secondary, 20)

.c-check-list-item
  figure
    border: 1px solid $white
    height: 20px
    width: 20px
    border-radius: 3px
    margin: 0
    margin: 10px
    position: relative
    &::after
      position: absolute
      top: 20px
      left: 0
      opacity: 0
      content: ''
      border-left: 3px solid lighten($primary, 10)
      border-bottom: 3px solid lighten($primary, 10)
      width: 20px
      height: 10px
      transform: rotateZ(-45deg)
      transition: all 0.3s ease-in-out
  &.selected
    figure
      &::after
        top: 0
        left: 0
        opacity: 1
