.content
  .l-admin.header
    position: relative
    margin: 0
    padding: 0
    height: 100%
    width: 100%
    background-size: cover
    background-image: url('../../../../public/bg-07.jpg')
    background-position: center
    background-repeat: no-repeat
    background-attachment: fixed
    .row
      margin-top: 100px
    .card
      header
        margin-bottom: 20px
      .table
        header
          margin: 0
        .table-body
          max-height: calc(100vh - 250px)

.l-admin
  .card
    .field.email-list-input
      ul
        padding-left: 12px
        li
          i
            cursor: pointer
            padding: 4px
            height: 10px
            width: 10px
            font-size: 10px
            background-color: lighten($secondary, 10)
            border-radius: 50%
            transition: all 0.3s ease-in-out
            margin-left: 8px
            &:hover
              background-color: lighten($secondary, 20)
      &.wrong
        input[type="email"]:focus
          outline: 1px solid red
