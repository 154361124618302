#full-data
  position: absolute
  left: 0
  top: 100%
  width: 100%
  height: calc(100% - 20px)
  background-color: $white
  z-index: 0
  border-radius: 40px
  transition: all 0.5s cubic-bezier(0.1, 1, 1, 0.1)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  &.open
    top: 20px
  .field
    display: flex
    height: 40px
    align-items: center
    label
      margin-left: 10px
  .table
    width: 90%
    height: calc(90% - 100px)
    box-shadow: 1px 1px 20px 4px rgba(0,0,0,0.2)
    border-radius: 5px
    display: flex
    flex-direction: column
    border-collapse: collapse
    overflow: hidden
    overflow-y: scroll
    .head
      position: sticky
      top: 0
      background-color: $secondary
      text-align: left
      color: $white
      width: 100%
      display: flex
      align-items: center
      box-shadow: 1px 1px 5px 5px rgba(0,0,0,0.2)
      .col
        height: 40px
        line-height: 40px
    .head,.row
      .col
        min-width: 100px
        padding-left: 5px
        padding-right: 5px
        flex: 1
        text-align: center
        cursor: default
        &.dir
          flex: 5
        &.driver
          flex: 2
        a
          span
            text-decoration: underline
            color: royalblue
    .row
      display: flex
      background-color: $white
      transition: background 0.2s ease-in-out
      &:nth-child(odd)
        background-color: $alternate-row
      &:hover
        background-color: $dark
        label,span
          color: $white !important
      .col
        display: flex
        flex-direction: column
        justify-content: center
        padding-top: 10px
        padding-bottom: 10px
        transition: background 0.2s ease-in-out, transform 0.2s ease-in-out 0.2s
        label.inital,label.final
          font-family: $menu-text
          font-weight: bold
          font-size: 11px
          align-self: start
          color: $dark
        span
          margin-left: 5px
          font-size: 12px
          font-weight: 500
        &.dir,&.coord
          span
            text-align: left
          &.hidden
            display: none
    .row,.head
      .col.coord
        min-width: 170px
        span
          display: flex
          align-items: center
          justify-content: space-between
