.base-drop-down
  display: flex
  flex-direction: column
  align-items: center
  padding: 0
  position: relative
  border-radius: 0
  background-color: transparent
  border: none
  height: 30px
  box-sizing: content-box
  position: relative
  .input
    position: absolute
    top: 0
    left: 0
    border-radius: $layout-border-radius
    display: flex
    align-items: center
    justify-content: space-between
    background-color: $white
    color: $dark
    width: calc(100% - 0px)
    height: 30px
    padding: 5px 7px
    border-radius: 3px
    min-width: 100px
    label
    i
      display: block
      height: 100%
      align-self: flex-end
      cursor: default
      transform: rotateZ(0deg)
      transition: $transition-default
      display: flex
      align-items: center
  ul
    position: absolute
    top: calc(100% - 0px)
    left: 0
    width: 100%
    height: 0px
    color: $dark
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.4)
    transition: $transition-default
    overflow: hidden
    z-index: 11
    li
      min-height: 30px
      background-color: $white
      display: flex
      align-items: center
      padding: 0px 10px
      cursor: pointer
      transition: $transition-default
      &:hover
        color: $white
        background-color: $dark

.base-drop-down.expand-up
  position: relative
  .input
    border: 1px solid silver
    height: 20px
    width: 120px
    transform: translateX(-40%)
    overflow: hidden
    flex-flow: row-reverse
    label
      position: absolute
      left: 5px
      height: 20px
      overflow: hidden
      width: 300px
    i
      background-color: $white
      transform: translateX(10px) rotatez(0deg)
  ul
    background-color: $white
    top: calc(0px - 70px)
    min-width: 150px
    z-index: 99
    transform: translateX(-40%) translateY(-50%) !important
    max-height: 300px
    border: 1px solid silver
    padding: 0 !important
    overflow-y: scroll !important
    opacity: 0

.base-drop-down.expand-up.open
  .input
    i
      transform: translateX(10px) rotateZ(180deg)
  ul
    top: calc(100% + 150px)
    height: auto !important
    opacity: 1

.base-drop-down
  &.open
    .input
      i
        transform: rotateZ(180deg)
    ul
      height: 100px
